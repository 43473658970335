.App {
  text-align: center;
  padding: 10;
  width: 100%;
}

.App-logo {
  height: 13vh;
  pointer-events: none;
  margin: 10px;
  min-height: 175px;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  background-color: #fff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 25px;
  font-family: "Roboto", "Helvetica", "Times New Roman", Times, serif;
}

.App-link {
  color: #61dafb;
}

.selectCityList svg {
  width: 0.5em;
}

.listNum {
  width: 5%;
  display: inline-block;
  vertical-align: top;
}

.listTxt {
  width: 90%;
  display: inline-block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[class*="Label"],
h6,
h4,
h3,
h2,
h1 {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

div.MuiContainer-root {
  padding: 0;
}

/* @media only screen and (max-width: 1028px) {
  label[data-shrink="false"] {
    display: none;
  }
} */

@media only screen and (max-width: 1303px) {
  /* label[data-shrink="false"] {
    display: none;
  } */
  div.longText label.MuiInputLabel-shrink {
    /* display: none; */
  }
  div label[data-shrink="true"]:not(.Mui-focused):nth-of-type(1) {
    display: none;
  }
  div label[data-shrink="true"].Mui-focused:nth-of-type(2) {
    display: none;
  }
  /* .MuiFormControl-root div, */
  /* label + .MuiInput-formControl {
    margin-top: 50px;
  } */
  label + .MuiInput-formControl {
    margin-top: 55px !important;
  }
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top center;
  }

  header #google_translate_element {
    position: relative;
    right: auto;
    top: auto;
  }
}
#google_translate_element {
  position: absolute;
  right: 25px;
  top: 25px;
}
